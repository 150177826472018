<template>
  <div v-if="invoice">
    <validation-observer ref="formValidation">
      <b-form class="auth-login-form mt-2" @submit.prevent>
        <div class="row">
          <div class="col">
            <b-form-group label="Cliente">
              <b-form-input :value="invoice.client.full_name" disabled />
            </b-form-group>
          </div>
          <div class="col">
            <b-form-group label="Método de pago *" label-for="payment_method">
              <validation-provider
                #default="{ errors }"
                name="Método de pago"
                rules="required"
              >
                <small class="text-danger">{{ errors[0] }}</small>
                <v-select
                  id="payment_method"
                  v-model="note.payment_method"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="
                    $store.state.app.payment_methods.filter(
                      i => i.hacienda === true
                    )
                  "
                  :reduce="payment_method => payment_method.id"
                  :clearable="false"
                  class="per-page-selector"
                  label="name"
                  name="Método de pago"
                />
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col">
            <b-form-group label="Medio de pago *" label-for="payment_form">
              <validation-provider
                #default="{ errors }"
                name="Medio de pago"
                rules="required"
              >
                <small class="text-danger">{{ errors[0] }}</small>
                <v-select
                  id="payment_form"
                  v-model="note.payment_form"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="$store.state.app.payment_forms"
                  :reduce="payment_form => payment_form.id"
                  :clearable="false"
                  class="per-page-selector"
                  label="name"
                  name="Medio de pago"
                />
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col">
            <b-form-group label="Moneda *" label-for="currency">
              <validation-provider
                #default="{ errors }"
                name="Moneda"
                rules="required"
              >
                <small class="text-danger">{{ errors[0] }}</small>
                <v-select
                  id="currency"
                  v-model="note.currency"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="$store.state.app.currencies"
                  :reduce="currency => currency.id"
                  :clearable="false"
                  class="per-page-selector"
                  label="name"
                  name="Moneda"
                  disabled
                />
              </validation-provider>
            </b-form-group>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col">
            <b-card no-body class="card-company-table">
              <b-table
                :items="invoice.transaction_details"
                responsive
                :fields="serviceColumns"
                class="mb-0"
              >
                <template #cell(price)="data">
                  {{ formatCurrency(data.item.price) }}
                </template>

                <template #cell(total)="data">
                  {{ formatCurrency(data.item.price) }}
                </template>

                <template #cell(iva)="data"> {{ data.item.tax }}% </template>
              </b-table>
            </b-card>
          </div>
        </div>

        <div class="row justify-content-end">
          <div class="col">
            <div class="col-lg-9 col-md-9 col-sm-12 col-12">
              <b-form-group label="Notas" label-for="notes">
                <validation-provider
                  #default="{ errors }"
                  name="Notas"
                  rules="required"
                >
                  <small class="text-danger">{{ errors[0] }}</small>
                  <b-form-textarea
                    v-model="note.note"
                    id="notes"
                    name="notes"
                    placeholder="Notas"
                    rows="2"
                    max-rows="5"
                  />
                </validation-provider>
              </b-form-group>

              <b-form-group label="Terminos y condiciones" label-for="terms">
                <b-form-textarea
                  v-model="note.terms"
                  id="terms"
                  placeholder="Terminos y condiciones"
                  rows="2"
                  max-rows="5"
                />
              </b-form-group>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-12 col-12">
            <b-list-group flush>
              <b-list-group-item>
                <b>Subtotal:</b> {{ formatCurrency(invoice.subtotal) }}
              </b-list-group-item>
              <b-list-group-item>
                <b>Total: </b> {{ formatCurrency(invoice.total) }}
              </b-list-group-item>
            </b-list-group>
          </div>
        </div>

        <hr />

        <div class="text-center">
          <b-overlay
            :show="overlay_submit"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
            class="d-inline-block"
          >
            <b-button
              type="submit"
              variant="gradient-primary"
              block
              @click="storeNote"
            >
              <feather-icon icon="SaveIcon" class="mr-50" />
              <span class="align-middle">Guardar</span>
            </b-button>
          </b-overlay>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormCheckbox,
  BOverlay,
  BFormTextarea,
  BTable,
  BCard,
  BListGroup,
  BListGroupItem
} from "bootstrap-vue";
import vSelect from "vue-select";

import { confirmAlert, currencyFormat } from "@/helpers";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import NoteService from "@/services/notes.service";
import Swal from "sweetalert2";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  props: {
    invoice: {
      default: null
    },
    note: {
      default: null
    }
  },
  components: {
    BListGroup,
    BListGroupItem,
    vSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BFormCheckbox,
    BOverlay,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BTable,
    BCard
  },
  data() {
    return {
      required,
      overlay_submit: false,
      serviceColumns: [
        { key: "cabys_code", label: "Código cabys" },
        { key: "name", label: "Servicio" },
        { key: "price", label: "Precio" },
        { key: "iva", label: "Iva (%)" },
        { key: "total", label: "Total" }
      ]
    };
  },
  methods: {
    formatCurrency(amount) {
      return currencyFormat(amount);
    },
    storeNote() {
      this.$refs.formValidation.validate().then(async success => {
        if (success) {
          try {
            this.overlay_submit = true;
            const dataNote = {
              invoice_id: this.invoice.id,
              payment_method: this.note.payment_method,
              payment_form: this.note.payment_form,
              note: this.note.note,
              terms: this.note.terms,
              note_type: this.note.note_type
            };
            const { data: res } = await NoteService.storeNote(dataNote);
            if (res.success) {
              this.toast(res.message);
              this.$router.push("/notes");
            } else {
              this.swal(res.message);
            }
            this.overlay_submit = false;
          } catch (error) {
            this.swal("Ha ocurrido un error al registrar la factura");
            this.overlay_submit = false;
          }
        }
      });
    },
    toast(title, variant = "success", icon = "CheckCircleIcon") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: icon,
          variant: variant
        }
      });
    },
    swal(title, content = "") {
      Swal.fire({
        title: title,
        text: content,
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary"
        },
        buttonsStyling: false
      });
    }
  }
};
</script>
