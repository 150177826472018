<template>
  <b-overlay :show="loading" rounded="sm">
    <b-card
      :title="
        `Generar nota de ${note_type_name} sobre la factura #${
          invoice ? invoice.reference : ''
        }`
      "
    >
      <b-card-text>
        <div class="row">
          <div class="col">
            <h3>
              Nota de {{ note_type_name }}
              <b class="text-primary">#{{ last_consecutive_note }}</b>
            </h3>
          </div>
          <div class="col text-right">
            <h4>
              {{ date }} <br />
              {{ time }}
            </h4>
          </div>
        </div>
        <form-note :invoice="invoice" :note="note" />
      </b-card-text>
    </b-card>
  </b-overlay>
</template>

<script>
import { BOverlay, BCard, BCardText } from "bootstrap-vue";
import InvoiceService from "@/services/invoices.service";
import FormNote from "@/components/FormNote";
import Swal from "sweetalert2";

import { confirmAlert } from "@/helpers";
import UtilitiesService from "@/services/utilities.service";

export default {
  components: {
    BOverlay,
    FormNote,
    BCard,
    BCardText
  },
  data() {
    return {
      loading: false,
      invoice: null,
      note: {
        currency: { name: "Colones", value: 1 },
        payment_method: "",
        payment_form: "",
        terms: "",
        note: ""
      },

      date: "",
      time: "",
      last_consecutive_note: "",

      week: [
        "LUNES",
        "MARTES",
        "MIERCOLES",
        "JUEVES",
        "VIERNES",
        "SABADO",
        "DOMINGO"
      ],
      clock: null
    };
  },
  computed: {
    note_type_name() {
      let name = "credito";
      if (this.$route.query.note_type === "nd") {
        name = "debito";
      }
      return name;
    }
  },
  methods: {
    async getInvoice() {
      try {
        this.loading = true;
        const { data: res } = await InvoiceService.getInvoice(
          this.$route.query.order
        );
        if (res.success) {
          this.invoice = res.data.invoice;
          this.note.payment_method = res.data.invoice.payment_method;
          this.note.payment_form = res.data.invoice.payment_form;
          this.note.note_type = this.$route.query.note_type;
          this.getConsecutiveNote(this.note.note_type);
        } else {
          this.swal(
            "Ha ocurrido un error al cargar la información de la factura"
          );
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.swal("Ha ocurrido un error al intentar cargar la información");
      }
    },
    async getConsecutiveNote(note_type) {
      try {
        const { data: res } = await UtilitiesService.getConsecutiveNote(
          note_type
        );
        this.last_consecutive_note = res;
      } catch (error) {
        this.swal("Ha ocurrido un error al intentar cargar la información");
      }
    },
    swal(title, content = "") {
      Swal.fire({
        title: title,
        text: content,
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary"
        },
        buttonsStyling: false
      });
    },
    startClock() {
      this.clock = setInterval(() => {
        let cd = new Date();
        this.time =
          this.zeroPadding(cd.getHours(), 2) +
          ":" +
          this.zeroPadding(cd.getMinutes(), 2) +
          ":" +
          this.zeroPadding(cd.getSeconds(), 2);
        this.date =
          this.week[cd.getDay()] +
          " " +
          this.zeroPadding(cd.getDate(), 2) +
          "-" +
          this.zeroPadding(cd.getMonth() + 1, 2) +
          "-" +
          this.zeroPadding(cd.getFullYear(), 4);
      }, 1000);
    },
    zeroPadding(num, digit) {
      let zero = "";
      for (var i = 0; i < digit; i++) {
        zero += "0";
      }
      return (zero + num).slice(-digit);
    }
  },
  created() {
    this.getInvoice();
    this.startClock();
  },
  beforeDestroy() {
    clearInterval(this.clock);
  }
};
</script>
