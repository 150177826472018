import api from "@/api";

class NoteService {
  static getNotes(page, search) {
    const params = { page: page, perPage: search.perPage };

    if (search.term) params.search = search.term;

    return api({
      method: "get",
      url: "notes",
      params: params
    });
  }

  static getNote(id) {
    return api({
      url: `note-detail/${id}`
    });
  }

  static storeNote(data) {
    return api({
      method: "post",
      data,
      url: "store-note"
    });
  }

  static generatePdf(note_id) {
    return api({
      url: "generate-note-pdf",
      params: { note_id },
      responseType: "blob"
    });
  }
}

export default NoteService;
