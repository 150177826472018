var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.invoice)?_c('div',[_c('validation-observer',{ref:"formValidation"},[_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('b-form-group',{attrs:{"label":"Cliente"}},[_c('b-form-input',{attrs:{"value":_vm.invoice.client.full_name,"disabled":""}})],1)],1),_c('div',{staticClass:"col"},[_c('b-form-group',{attrs:{"label":"Método de pago *","label-for":"payment_method"}},[_c('validation-provider',{attrs:{"name":"Método de pago","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('v-select',{staticClass:"per-page-selector",attrs:{"id":"payment_method","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.$store.state.app.payment_methods.filter(
                    function (i) { return i.hacienda === true; }
                  ),"reduce":function (payment_method) { return payment_method.id; },"clearable":false,"label":"name","name":"Método de pago"},model:{value:(_vm.note.payment_method),callback:function ($$v) {_vm.$set(_vm.note, "payment_method", $$v)},expression:"note.payment_method"}})]}}],null,false,3221190527)})],1)],1),_c('div',{staticClass:"col"},[_c('b-form-group',{attrs:{"label":"Medio de pago *","label-for":"payment_form"}},[_c('validation-provider',{attrs:{"name":"Medio de pago","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('v-select',{staticClass:"per-page-selector",attrs:{"id":"payment_form","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.$store.state.app.payment_forms,"reduce":function (payment_form) { return payment_form.id; },"clearable":false,"label":"name","name":"Medio de pago"},model:{value:(_vm.note.payment_form),callback:function ($$v) {_vm.$set(_vm.note, "payment_form", $$v)},expression:"note.payment_form"}})]}}],null,false,219407884)})],1)],1),_c('div',{staticClass:"col"},[_c('b-form-group',{attrs:{"label":"Moneda *","label-for":"currency"}},[_c('validation-provider',{attrs:{"name":"Moneda","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('v-select',{staticClass:"per-page-selector",attrs:{"id":"currency","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.$store.state.app.currencies,"reduce":function (currency) { return currency.id; },"clearable":false,"label":"name","name":"Moneda","disabled":""},model:{value:(_vm.note.currency),callback:function ($$v) {_vm.$set(_vm.note, "currency", $$v)},expression:"note.currency"}})]}}],null,false,1178519029)})],1)],1)]),_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col"},[_c('b-card',{staticClass:"card-company-table",attrs:{"no-body":""}},[_c('b-table',{staticClass:"mb-0",attrs:{"items":_vm.invoice.transaction_details,"responsive":"","fields":_vm.serviceColumns},scopedSlots:_vm._u([{key:"cell(price)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.formatCurrency(data.item.price))+" ")]}},{key:"cell(total)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.formatCurrency(data.item.price))+" ")]}},{key:"cell(iva)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.tax)+"% ")]}}],null,false,2821195255)})],1)],1)]),_c('div',{staticClass:"row justify-content-end"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"col-lg-9 col-md-9 col-sm-12 col-12"},[_c('b-form-group',{attrs:{"label":"Notas","label-for":"notes"}},[_c('validation-provider',{attrs:{"name":"Notas","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('b-form-textarea',{attrs:{"id":"notes","name":"notes","placeholder":"Notas","rows":"2","max-rows":"5"},model:{value:(_vm.note.note),callback:function ($$v) {_vm.$set(_vm.note, "note", $$v)},expression:"note.note"}})]}}],null,false,1865474791)})],1),_c('b-form-group',{attrs:{"label":"Terminos y condiciones","label-for":"terms"}},[_c('b-form-textarea',{attrs:{"id":"terms","placeholder":"Terminos y condiciones","rows":"2","max-rows":"5"},model:{value:(_vm.note.terms),callback:function ($$v) {_vm.$set(_vm.note, "terms", $$v)},expression:"note.terms"}})],1)],1)]),_c('div',{staticClass:"col-lg-3 col-md-3 col-sm-12 col-12"},[_c('b-list-group',{attrs:{"flush":""}},[_c('b-list-group-item',[_c('b',[_vm._v("Subtotal:")]),_vm._v(" "+_vm._s(_vm.formatCurrency(_vm.invoice.subtotal))+" ")]),_c('b-list-group-item',[_c('b',[_vm._v("Total: ")]),_vm._v(" "+_vm._s(_vm.formatCurrency(_vm.invoice.total))+" ")])],1)],1)]),_c('hr'),_c('div',{staticClass:"text-center"},[_c('b-overlay',{staticClass:"d-inline-block",attrs:{"show":_vm.overlay_submit,"rounded":"","opacity":"0.6","spinner-small":"","spinner-variant":"primary"}},[_c('b-button',{attrs:{"type":"submit","variant":"gradient-primary","block":""},on:{"click":_vm.storeNote}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SaveIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Guardar")])],1)],1)],1)])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }